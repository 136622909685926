import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { firebase, storage } from "../../../Firebase";
import Loader from "react-loader-spinner";

const Car = ({ car }) => {
  const [editMode, setEditMode] = useState(false);
  const [price, setPrice] = useState(car.price);
  const [carImage, setCarImage] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    if (car.image) {
      storage
        .ref(car.image)
        .getDownloadURL()
        .then(url => {
          setCarImage(url);
        })
        .finally(() => setIsImageLoading(false));
    }
  }, [car]);

  const handlePriceUpdate = () => {
    const db = firebase.firestore();
    db.collection("cars")
      .doc(car.id)
      .update({ price: price })
      .then(() => {
        setEditMode(false);
      })
      .catch(() => {
        setEditMode(false);
        setPrice(car.price);
      });
  };
  return (
    <div className="car">
      <div className="car-image">
        {isImageLoading ? (
          <div className="loading-container">
            <Loader type="ThreeDots" color="#9f9f9f" width={60} />
          </div>
        ) : (
          <img src={carImage} alt={`${car.name} image`} width="100%" />
        )}
      </div>

      <p className="car-name">{car.name}</p>

      <div className="price-container">
        {!editMode && (
          <span className="car-price">
            <span className="price-value">{price},00</span> eur
          </span>
        )}
        {editMode && (
          <div className="edit-mode">
            <input
              type="number"
              value={price}
              onChange={e => setPrice(e.target.value)}
            />
            <button onClick={handlePriceUpdate}>SAVE</button>
          </div>
        )}

        {!editMode && (
          <FontAwesomeIcon
            onClick={() => {
              setEditMode(true);
            }}
            icon={faEdit}
            className="price-edit-icon"
          ></FontAwesomeIcon>
        )}
      </div>
    </div>
  );
};

export { Car };
