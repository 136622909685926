import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap as GoogleMAP,
  Marker,
  InfoWindow,
} from "react-google-maps";
const styles = require("./GoogleMapStyles.json");
const Headquartes = require("./Marker.svg");
const Flag = require("./Flag.svg");

const GoogleMap = withScriptjs(
  withGoogleMap((props: any) => {
    const { locations, headquarter } = props;
    const [headInfoVisible, setHeadInfoVisible] = useState<boolean>(false);
    const { t } = useTranslation();
    const [infoVisible, setInfoVisible] = useState<any>({});

    const [center, setCenter] = useState<any>(props.mapCenter);
    const [zoom, setZoom] = useState<number>(props.zoom);

    useEffect(() => {
      setHeadInfoVisible(true);
    }, []);

    useEffect(() => {
      if (props.mapCenter) setCenter(props.mapCenter);
    }, [props.mapCenter]);
    useEffect(() => {
      if (props.zoom) setZoom(props.zoom);
    }, [props.zoom]);
    useEffect(() => {
      if (props.locationPressed) {
        updateInfoWindows(props.locationPressed);
      }
    }, [props.locationPressed]);

    const updateInfoWindows = (location: any) => {
      if (headInfoVisible && location.coordinates !== headquarter.coordinates) {
        setHeadInfoVisible(false);
      }
      let infoVisibleUpdate = infoVisible;
      const keys = Object.keys(infoVisible);
      keys.forEach((item) => {
        infoVisibleUpdate[item] = false;
      });
      infoVisibleUpdate[location.id] = true;
      setInfoVisible(infoVisibleUpdate);
    };
    return (
      <GoogleMAP
        defaultZoom={zoom}
        defaultCenter={{
          lat: center.lat, // latitude for the center of the map
          lng: center.lng, // longitude for the center of the map
        }}
        defaultOptions={{
          disableDefaultUI: true, // disable default map UI
          draggable: true, // make map draggable
          keyboardShortcuts: false, // disable keyboard shortcuts
          scaleControl: true, // allow scale controle
          scrollwheel: true, // allow scroll wheel
          styles: styles, // change default map styles
        }}
      >
        {locations.map((item: any, index: number) =>
          item.coordinates ? (
            <Marker
              key={index}
              icon={Flag}
              position={{
                lat: item.coordinates.lat, // latitude to position the marker
                lng: item.coordinates.lng, // longitude to position the marker
              }}
              onClick={() =>
                setInfoVisible({ ...infoVisible, [item.id]: true })
              }
            >
              {infoVisible[item.id] && (
                <InfoWindow
                  position={{
                    lat: headquarter.coordinates.lat,
                    lng: headquarter.coordinates.lng,
                  }}
                  onCloseClick={() =>
                    setInfoVisible({ ...infoVisible, [item.id]: false })
                  }
                  options={{ overflowX: "hidden" }}
                >
                  <div className="map-info">
                    <h4 className="heading">{item.name}</h4>

                    <span className={`fee ${item.fee ? "charge" : "free"}`}>
                      {item.fee
                        ? t("locations.infoWindow.fee")
                        : t("locations.infoWindow.free")}
                    </span>
                    <div
                      className="overlay"
                      onClick={() =>
                        setInfoVisible({ ...infoVisible, [item.id]: false })
                      }
                    />
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ) : null
        )}
        <Marker
          icon={Headquartes}
          position={{
            lat: headquarter.coordinates.lat, // latitude to position the marker
            lng: headquarter.coordinates.lng, // longitude to position the marker
          }}
          onClick={() => setHeadInfoVisible(true)}
        />
        {headInfoVisible && (
          <InfoWindow
            position={{
              lat: headquarter.coordinates.lat,
              lng: headquarter.coordinates.lng,
            }}
            onCloseClick={() => setHeadInfoVisible(false)}
          >
            <div className="map-info">
              <h4 className="heading">
                {t("locations.infoWindow.headquarter")}
              </h4>

              <span className="fee">{t("locations.infoWindow.free")}</span>
              <div
                className="overlay"
                onClick={() => setHeadInfoVisible(false)}
              />
            </div>
          </InfoWindow>
        )}
      </GoogleMAP>
    );
  })
);

export { GoogleMap };
