import * as FIREBASE from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAnF49CHHDAy2qHvqnev1zCHIr0XY5Iclg",
  authDomain: "rent-a-car-prestige.firebaseapp.com",
  projectId: "rent-a-car-prestige",
  storageBucket: "rent-a-car-prestige.appspot.com",
  messagingSenderId: "780827181815",
  appId: "1:780827181815:web:9d9a0652980958a69c89f2",
  measurementId: "G-LJMFZ4CD6Q",
};

if (!FIREBASE.apps.length) {
  FIREBASE.initializeApp(firebaseConfig);
}
const firebase = FIREBASE;
const storage = firebase.storage();

export { firebase, storage };
