import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Car } from "./Car";
import { firebase } from "../../Firebase";
import { ICar } from "components/Fleet/Car";
import { Loading } from "components/Loading";
import { Services } from "components/Services";
import { Footer } from "components/Footer";
import { Formik } from "formik";
import { TextInput } from "components/TextInput";
import { DropdownInput } from "components/DropdownInput";
import { locations } from "components/Home";
import Datepicker from "react-datepicker";
import Calendar from "static/SVG/Reservation/Calendar.svg";

import * as Yup from "yup";

import Driver from "static/SVG/Quote/AdditionalDriver.svg";
import Baby from "static/SVG/Quote/BabySeat.svg";
import Gps from "static/SVG/Quote/GpsDevice.svg";
import Wifi from "static/SVG/Quote/Wifi.svg";
import Border from "static/SVG/Quote/Border.svg";
import Ferry from "static/SVG/Quote/Ferry.svg";

import { ReactSVG } from "react-svg";
import { Modal } from "components/Modal";
import { COMPANY_PHONE } from "static/constants";

export interface IGetQuoteProps {
  location: any;
  match: any;
  history: any;
}
export interface IExtra {
  text: string;
  name:
    | "additionalDriver"
    | "gpsDevice"
    | "babySeat"
    | "wifi"
    | "ferryFee"
    | "crossBorder";
  price: number;
  maxAmount?: number;
  calculation: "daily" | "once";
  icon: string;
}

const GetQuote: React.FC<IGetQuoteProps> = ({ location, match, history }) => {
  const { t, i18n } = useTranslation();
  const [price, setPrice] = useState<number>(0);
  const carID = match.params["carId"];
  const [car, setCar] = useState<ICar>();
  const [carError, setCarError] = useState<boolean>(false);
  const [carLoading, setCarLoading] = useState<boolean>(true);
  const [cars, setCars] = useState<ICar[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [pickupDate, setPickupDate] = useState<Date | null>(
    location.params && location.params.pickupDate
      ? location.params.pickupDate
      : null
  );
  const [returnDate, setReturnDate] = useState<Date | null>(
    location.params && location.params.returnDate
      ? location.params.returnDate
      : null
  );

  const [extrasCurrent, setExtrasCurrent] = useState<any>({
    additionalDriver: 0,
    babySeat: 0,
    gpsDevice: false,
    wifi: false,
    ferryFee: false,
    crossBorder: false,
  });
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const inputContainers = document.getElementsByClassName(
    "react-datepicker-wrapper"
  );
  if (inputContainers && inputContainers.length > 0) {
    inputContainers[0].children[0].children[0].setAttribute("readonly", "true");
    inputContainers[1].children[0].children[0].setAttribute("readonly", "true");
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(t("validationMessage.required")),
    email: Yup.string()
      .email(t("validationMessage.email"))
      .required(t("validationMessage.required")),
    car: Yup.string().required(t("validationMessage.required")),
    pickupLocation: Yup.string().required(t("validationMessage.required")),
    returnLocation: Yup.string().required(t("validationMessage.required")),
    pickupDate: Yup.date().nullable().required(t("validationMessage.required")),
    returnDate: Yup.date().nullable().required(t("validationMessage.required")),
  });

  const extras: IExtra[] = [
    {
      name: "additionalDriver",
      text: t("quote.extras.additionalDriver"),
      price: 5,
      calculation: "daily",
      maxAmount: 10,
      icon: Driver,
    },
    {
      name: "gpsDevice",
      text: t("quote.extras.gpsDevice"),
      price: 20,
      calculation: "once",
      icon: Gps,
    },
    {
      name: "wifi",
      text: t("quote.extras.mobileWifi"),
      price: 15,
      calculation: "daily",
      icon: Wifi,
    },
    {
      name: "babySeat",
      text: t("quote.extras.babySeat"),
      price: 5,
      calculation: "daily",
      maxAmount: 10,
      icon: Baby,
    },
    {
      name: "ferryFee",
      text: t("quote.extras.ferryFee"),
      price: 20,
      calculation: "once",
      icon: Ferry,
    },
    {
      name: "crossBorder",
      text: t("quote.extras.crossBorder"),
      price: 50,
      calculation: "once",
      icon: Border,
    },
  ];

  useEffect(() => {
    const db = firebase.firestore();
    const getCar = () => {
      db.collection("cars")
        .doc(carID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            const car: ICar = {
              name: (data as any).name,
              price: (data as any).price,
              id: (data as any).id,
              image: (data as any).image,
              type: (data as any).type,
              manual: (data as any).manual,
              automatic: (data as any).automatic,
              airConditioning: (data as any).airConditioning,
              persons: (data as any).persons,
              bags: (data as any).bags,
              sorting: (data as any).sorting,
            };
            setCarError(false);
            setCar(car);
          }
        })
        .catch(() => setCarError(true))
        .finally(() => setCarLoading(false));
    };

    const getAllCars = () => {
      db.collection("cars")
        .get()
        .then((querySnapshot) => {
          let carsFromDB: ICar[] = [];
          querySnapshot.forEach((doc) => {
            const car = doc.data();
            carsFromDB.push({
              name: car.name,
              price: car.price,
              id: doc.id,
              image: car.image,
              type: car.type,
              manual: car.manual,
              automatic: car.automatic,
              airConditioning: car.airConditioning,
              persons: car.persons,
              bags: car.bags,
              sorting: car.sorting,
            });
          });
          const sorted = carsFromDB.sort((a, b) => a.sorting - b.sorting);
          setCars([...sorted]);
        });
    };

    getCar();
    getAllCars();
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (pickupDate && returnDate) {
      let days = Math.floor(
        (returnDate.getTime() - pickupDate.getTime() - 1) /
          (1000 * 60 * 60 * 24)
      );
      days = days + 1;
      let extrasPrice = 0;
      extras.forEach((item: IExtra) => {
        const dailyRate = item.calculation === "daily" ? days : 1;
        if (item.maxAmount) {
          extrasPrice += extrasCurrent[item.name] * item.price * dailyRate;
        } else {
          if (extrasCurrent[item.name]) {
            extrasPrice += item.price * dailyRate;
          }
        }
      });
      setPrice(car?.price * days + extrasPrice);
    }
  }, [car?.price, pickupDate, returnDate, extrasCurrent]);

  let initialPickupLocation = "";
  let initialPickupDate = null;
  let initialReturnDate = null;

  if (location.params) {
    ///we have parameters from reservation box
    if (location.params.pickupLocation) {
      initialPickupLocation = location.params.pickupLocation;
    }
    if (location.params.pickupDate) {
      initialPickupDate = location.params.pickupDate;
    }
    if (location.params.returnDate) {
      initialReturnDate = location.params.returnDate;
    }
  }
  if (location.filter !== undefined) {
    //we have filter
  }

  const getCarTransmissionOptions = () => {
    if (car) {
      let options = [];
      if (car.manual) {
        options.push(t("quote.transmission.manual"));
      }
      if (car.automatic) {
        options.push(t("quote.transmission.automatic"));
      }
      return options;
    }
    return [];
  };

  let mainRender = null;
  if (carLoading) {
    mainRender = (
      <div className="loading-wrap">
        <Loading text={t("quote.loading")} />
      </div>
    );
  } else {
    if (carError) {
      mainRender = (
        <p
          style={{
            paddingTop: "60px",
            paddingBottom: "20px",
            color: "#919191",
          }}
        >
          {t("quote.error")}
        </p>
      );
    } else {
      mainRender = (
        <div className="get-quote" id="desktop-scrollTo">
          <p className="description">{t("quote.description")}</p>
          <Formik
            validationSchema={validationSchema}
            onSubmit={async (values: any, { resetForm }) => {
              const dataForEmail: any = {
                ...values,
                pickupDate: new Date(values.pickupDate).toLocaleDateString(
                  undefined,
                  {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }
                ),
                returnDate: new Date(values.returnDate).toLocaleDateString(
                  undefined,
                  {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }
                ),

                transmission:
                  values.transmission.length > 0
                    ? values.transmission
                    : "Not provided",
                phone: values.phone.length > 0 ? values.phone : "Not provided",
                note: values.note.length > 0 ? values.note : "Not provided",
                price: price,
                additionalDriver:
                  extrasCurrent.additionalDriver === 0
                    ? "No"
                    : extrasCurrent.additionalDriver,
                babySeat:
                  extrasCurrent.babySeat === 0 ? "No" : extrasCurrent.babySeat,
                gpsDevice: !extrasCurrent.gpsDevice ? "No" : "Yes",
                mobileWifi: !extrasCurrent.wifi ? "No" : "Yes",
                ferryFee: !extrasCurrent.ferryFee ? "No" : "Yes",
                crossBorder: !extrasCurrent.crossBorder ? "No" : "Yes",
              };
              await fetch(
                "https://us-central1-rent-a-car-prestige.cloudfunctions.net/app/quote",
                {
                  method: "POST",
                  body: JSON.stringify(dataForEmail),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((res) => res.json())
                .then(() => {
                  setModalVisible(true);
                  setSuccess(true);
                })
                .catch(() => {
                  setModalVisible(true);
                  setSuccess(false);
                })
                .finally(() => setIsSubmitting(false));
            }}
            initialValues={{
              name: "",
              email: "",
              phone: "",
              car: car?.name,
              transmission: "",
              pickupLocation: initialPickupLocation,
              returnLocation: "",
              pickupDate: initialPickupDate,
              returnDate: initialReturnDate,
              note: "",
            }}
          >
            {(props) => (
              <div className="reservation-container zoomIn animated">
                <div className="main">
                  <div className="car">{car && <Car car={car} />}</div>
                  <div className="form" id="mobile-scrollTo">
                    <div className="row">
                      <div className="input-wrap">
                        <TextInput
                          name="name"
                          placeholder={t("quote.name")}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.name}
                        />
                        {props.errors.name && props.touched.name && (
                          <span className="error-message">
                            {props.errors.name}
                          </span>
                        )}
                      </div>

                      <div className="input-wrap">
                        <TextInput
                          name="email"
                          placeholder={t("quote.emailAdress")}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                        />
                        {props.errors.email && props.touched.email && (
                          <span className="error-message">
                            {props.errors.email}
                          </span>
                        )}
                      </div>
                      <div className="input-wrap">
                        <TextInput
                          name="phone"
                          placeholder={t("quote.phoneNumber")}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.phone}
                        />
                        {props.errors.phone && props.touched.phone && (
                          <span className="error-message">
                            {props.errors.phone}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-wrap">
                        <DropdownInput
                          name="car"
                          initialValue={props.values.car}
                          onChange={(value: string) => {
                            let newCar = cars.filter(
                              (item) => item.name === value
                            )[0];
                            history.push({
                              pathname: "/GetQuote/" + newCar.id,
                              params: {},
                            });

                            props.setFieldValue("car", value);
                            setCar(newCar);
                          }}
                          placeholder={t("quote.pickCar")}
                          options={cars.map((item: ICar) => item.name)}
                        />
                        {props.errors.car && props.touched.car && (
                          <span className="error-message">
                            {props.errors.car}
                          </span>
                        )}
                      </div>
                      <div className="input-wrap">
                        <DropdownInput
                          name="transmission"
                          initialValue={props.values.transmission}
                          onChange={(value: string) =>
                            props.setFieldValue("transmission", value)
                          }
                          placeholder={t("quote.transmission.placeholder")}
                          options={getCarTransmissionOptions()}
                        />
                        {props.errors.transmission &&
                          props.touched.transmission && (
                            <span className="error-message">
                              {props.errors.transmission}
                            </span>
                          )}
                      </div>
                      <div className="input-wrap">
                        <DropdownInput
                          name="pickupLocation"
                          initialValue={props.values.pickupLocation}
                          onChange={(value: string) =>
                            props.setFieldValue("pickupLocation", value)
                          }
                          placeholder={t("quote.pickupLocation")}
                          options={locations
                            .filter((item: any) => item.pickUp)
                            .map((item: any) => item.name)}
                        />
                        {props.errors.pickupLocation &&
                          props.touched.pickupLocation && (
                            <span className="error-message">
                              {props.errors.pickupLocation}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-wrap">
                        <DropdownInput
                          name="returnLocation"
                          initialValue={props.values.returnLocation}
                          onChange={(value: string) =>
                            props.setFieldValue("returnLocation", value)
                          }
                          placeholder={t("quote.returnLocation")}
                          options={locations
                            .filter((item: any) => item.dropOff)
                            .map((item: any) => item.name)}
                        />
                        {props.errors.returnLocation &&
                          props.touched.returnLocation && (
                            <span className="error-message">
                              {props.errors.returnLocation}
                            </span>
                          )}
                      </div>
                      <div className="input-wrap">
                        <div className="datepicker-holder">
                          <span className="calendar-icon">
                            <ReactSVG src={Calendar} />
                          </span>
                          <Datepicker
                            name="pickupDate"
                            showTimeSelect
                            dateFormat="dd/MM/yyyy HH:mm"
                            timeFormat="HH:mm"
                            selected={props.values.pickupDate}
                            onChange={(date: Date | null) => {
                              props.setFieldValue("pickupDate", date);
                              setPickupDate(date);
                              if (
                                props.values.returnDate &&
                                date &&
                                props.values.returnDate < date
                              ) {
                                props.setFieldValue("returnDate", null);
                                setReturnDate(null);
                              }
                            }}
                            placeholderText={t("home.reservation.pickupDate")}
                            minDate={new Date()}
                          />
                        </div>
                        {props.errors.pickupDate &&
                          props.touched.pickupDate && (
                            <span className="error-message">
                              {props.errors.pickupDate}
                            </span>
                          )}
                      </div>
                      <div className="input-wrap">
                        <div className="datepicker-holder">
                          <span className="calendar-icon">
                            <ReactSVG src={Calendar} />
                          </span>
                          <Datepicker
                            name="returnDate"
                            selected={props.values.returnDate}
                            showTimeSelect
                            dateFormat="dd/MM/yyyy HH:mm"
                            timeFormat="HH:mm"
                            onChange={(date: Date | null) => {
                              props.setFieldValue("returnDate", date);
                              setReturnDate(date);
                            }}
                            placeholderText={t("home.reservation.returnDate")}
                            minDate={
                              props.values.pickupDate
                                ? props.values.pickupDate
                                : new Date()
                            }
                          />
                        </div>
                        {props.errors.returnDate &&
                          props.touched.returnDate && (
                            <span className="error-message">
                              {props.errors.returnDate}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="text-area-container">
                      <textarea
                        className="additionalNote"
                        name="note"
                        value={props.values.note}
                        onChange={props.handleChange}
                        placeholder={t("quote.additionalNote")}
                      />
                    </div>
                  </div>
                </div>
                <div className="extras">
                  <div className="info">
                    <p>{t("quote.extras.info")}</p>
                  </div>
                  <div className="select">
                    {extras.map((item: IExtra) => (
                      <div key={item.name} className="extra-container">
                        <span className="left">
                          <ReactSVG src={item.icon} />
                        </span>
                        <span className="right">
                          <span className="name">{item.text}</span>
                          <span className="price">
                            {item.price},00 eur
                            {item.calculation === "daily" && (
                              <span className="calculation">
                                ({t("quote.extras.daily")})
                              </span>
                            )}
                          </span>

                          {item.maxAmount && (
                            <div className="bottom">
                              <div className="minus-plus">
                                <span
                                  onClick={() => {
                                    let current = extrasCurrent[item.name];
                                    if (current > 0) {
                                      current = current - 1;
                                    }
                                    setExtrasCurrent({
                                      ...extrasCurrent,
                                      [item.name]: current,
                                    });
                                  }}
                                  className="minus"
                                >
                                  -
                                </span>
                                <span className="amount">
                                  {extrasCurrent[item.name]}
                                </span>
                                <span
                                  onClick={() => {
                                    let current = extrasCurrent[item.name];
                                    if (
                                      item.maxAmount &&
                                      current < item.maxAmount
                                    ) {
                                      current = current + 1;
                                    }
                                    setExtrasCurrent({
                                      ...extrasCurrent,
                                      [item.name]: current,
                                    });
                                  }}
                                  className="plus"
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          )}
                          {!item.maxAmount && (
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={extrasCurrent[item.name]}
                                onChange={() =>
                                  setExtrasCurrent({
                                    ...extrasCurrent,
                                    [item.name]: !extrasCurrent[item.name],
                                  })
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="finish">
                  <div className="price">
                    {props.values.pickupDate && props.values.returnDate && (
                      <>
                        <span className="">
                          {t("quote.price")}
                          <span className="price animated bounceIn">
                            {price},00
                          </span>{" "}
                          eur
                        </span>
                        <p className="location-charge">
                          {t("quote.additionalChargesLocation")}
                        </p>
                      </>
                    )}
                  </div>

                  <button
                    type="button"
                    onClick={async () => {
                      if (!props.isSubmitting) {
                        await props.validateForm().then((errors) => {
                          const keys = Object.keys(errors);
                          if (keys.length > 0) {
                            if (window.innerWidth < 1249) {
                              const position =
                                document.getElementById("mobile-scrollTo");
                              if (position) {
                                window.scroll(0, position.offsetTop - 80);
                              }
                            } else {
                              const position =
                                document.getElementById("desktop-scrollTo");
                              if (position) {
                                window.scroll(0, position.offsetTop - 80);
                              }
                            }
                          } else {
                            setIsSubmitting(true);
                          }
                        });

                        props.handleSubmit();
                      }
                    }}
                    className="submit"
                  >
                    {props.isSubmitting
                      ? t("quote.submitting")
                      : t("quote.submit")}
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      );
    }
  }

  return (
    <div className="fullWidth">
      <div className="quote">
        <div className="section-top">
          <div className="quote-content">
            <h1>{t("quote.heading1")}</h1>
            <h2>{t("quote.heading2")}</h2>
            <a className="telephone" href={`tel:${COMPANY_PHONE}`}>
              {COMPANY_PHONE}
            </a>
          </div>
        </div>
        <main>
          <div className="quote-content">{mainRender}</div>
        </main>
      </div>
      <Services />
      <Footer />
      <Modal
        submiting
        isVisible={isSubmitting}
        message={t("validationMessage.subbmitingRequest")}
      />
      <Modal
        isVisible={isModalVisible}
        message={
          success
            ? t("validationMessage.getQuoteSucces")
            : t("validationMessage.emailReject")
        }
        success={success}
        hideModal={() => {
          setModalVisible(false);
          if (success) {
            history.push("/");
            window.scroll(0, 0);
          }
        }}
      />
    </div>
  );
};

export { GetQuote };
